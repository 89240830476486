/**
 * The function `getGdprConsentToken` interacts with the TCF API (Transparency & Consent Framework) to retrieve the GDPR consent token
 * and the `gdprApplies` value (true if the publisher has configured CMP to apply GDPR to all visitors, including non-EEA).
 *
 * Once the GDPR consent token (`tcString`) is retrieved:
 * - It stores the consent token and `gdprApplies` flag in `window.JSGlobals`.
 * - If GDPR applies and a consent string is available, it sends the consent token to Permutive via `window.permutive.consent()`.
 * - If no consent string is available, it opts the user out of Permutive tracking.
 *
 * In cases where consent retrieval fails or Permutive is not available, the function logs errors or warnings accordingly.
 *
 * @remarks
 * This function assumes that `window.__tcfapi` (the TCF API) and `window.permutive` (Permutive SDK) are globally available.
 *
 * @function
 * @returns {void} This function does not return any value.
 */
const removeEventListener = (listenerId: number) => {
  ;(window as Window).__tcfapi('removeEventListener', 2, () => {}, listenerId)
}

type TcData = {
  purpose: {
    consents: {
      [purposeId: number]: boolean // Maps purpose ID (1-10 for standard TCF purposes) to consent status (true/false)
    }
  }
  tcString: string
  gdprApplies: boolean
  eventStatus: string
  listenerId: number
}

/**
 * The function `getGdprConsentToken` interacts with the TCF API (Transparency & Consent Framework) to retrieve the GDPR consent token
 * and the `gdprApplies` value (true if the publisher has configured CMP to apply GDPR to all visitors, including non-EEA).
 *
 * Once the GDPR consent token (`tcString`) is retrieved:
 * - It stores the consent token and `gdprApplies` flag in `window.JSGlobals`.
 * - If GDPR applies and a consent string is available, it checks the user's consent for specific purposes using `tcData.purpose.consents`.
 *   In this case, consent for Purpose 1 (typically storage access) is checked. If consent is granted, the consent token is sent to Permutive via `window.permutive.consent()`.
 * - If consent is not granted for the relevant purposes or no consent string is available, the user is opted out of Permutive tracking.
 *
 * In cases where consent retrieval fails or Permutive is not available, the function logs errors or warnings accordingly and opts the user out of tracking.
 *
 * @remarks
 * This function assumes that `window.__tcfapi` (the TCF API) and `window.permutive` (Permutive SDK) are globally available.
 * It also assumes that the `purpose.consents` object maps consent for specific purposes, and Purpose 1 is particularly relevant in this context.
 *
 * @function
 * @returns {void} This function does not return any value.
 */
export const getGdprConsentToken = () => {
  ;(window as Window).__tcfapi(
    'addEventListener',
    2,
    (tcData: TcData, success: boolean) => {
      if (
        success &&
        (tcData.eventStatus === 'tcloaded' ||
          tcData.eventStatus === 'useractioncomplete')
      ) {
        if (window.JSGlobals) {
          // Store the consent data in JSGlobals
          window.JSGlobals.consentString = tcData?.tcString
          window.JSGlobals.gdprApplies = tcData?.gdprApplies

          // Remove the listener
          removeEventListener(tcData?.listenerId)

          if (tcData?.gdprApplies) {
            const hasConsent = tcData?.purpose?.consents?.[1]

            if (hasConsent && window.permutive) {
              window.permutive.consent({
                opt_in: true,
                token: tcData?.tcString,
              })
            } else if (window.permutive) {
              // No consent for required purposes, opt-out the user
              window.permutive.consent({
                opt_in: false,
              })
              console.warn('Consent not granted, opting user out')
            } else {
              console.error('Permutive not available')
            }
          } else {
            console.warn('GDPR does not apply')
          }
        } else {
          console.error('JSGlobals not available')
        }
      } else {
        console.error('GDPR consent string not available')
        removeEventListener(tcData?.listenerId)

        // Opt the user out if consent retrieval fails and Permutive is available
        if (window.permutive) {
          window.permutive.consent({
            opt_in: false,
          })
          console.warn('Consent retrieval failed, opting user out')
        }
      }
    },
  )
}
