/*global JSGlobals, GaaMeteringRegwall, tp*/
import { jsLoader } from '~/util/fileLoaders'
import {
  isGaa,
  log,
  anonymousUserHasAccess,
  isUserRegistered,
  formatUserState,
} from './helper'
import { login, openSubscriptionPage, isLogin } from '~/js/piano'
import metering from './metering'
import {
  GOOGLE_EXTENDED_ACCESS,
  GOOGLE_INVALID_EXTENDED_ACCESS,
  METER_EXPIRED,
  METER_ACTIVE,
  SUBSCRIPTION_ACTIVE,
  NOT_METERED,
} from './constant'

export const initGoogleExtendedAccess = (pianoMeter) =>
  new Promise((resolve) => {
    // not applicable if user is not coming from google news showcase
    // also not interested if the the publication has no subscription piano enabled

    if (!JSGlobals.piano?.subscription) return resolve(null)

    log('pianoMeter', pianoMeter)
    if (location.search.includes('response_id') && !tp.pianoId.isUserValid()) {
      metering
        .fetchPianoSignInToken()
        .then((token) => tp.pianoId.loginByToken(token))
        .then(location.reload())
    }

    jsLoader(
      [
        { src: 'https://news.google.com/swg/js/v1/swg-gaa.js', async: true },
        {
          src: 'https://news.google.com/swg/js/v1/swg.js',
          async: true,
          'subscriptions-control': 'manual',
        },
      ],
      'googlesubscribe',
    )

    const swgReady = (callback) => {
      ;(self.SWG = self.SWG || []).push(function (subscriptions) {
        callback(subscriptions)
      })
    }

    swgReady((subscriptions) => {
      subscriptions.init(`${JSGlobals.domain}:showcase`)

      const checkShowcaseEntitlement = (userState) => {
        if (userState) {
          // Send userState to Google
          subscriptions.getEntitlements(userState)
          // log('checkShowcaseEntitlement > isSubscribed')
        } else {
          // log('checkShowcaseEntitlement > showGoogleRegwall')
          showGoogleRegwall()
        }
      }

      const syncEntitlementsWithGoogle = () => {
        return checkShowcaseEntitlement(getUserState())
      }

      // Show the Google registration intervention.
      const showGoogleRegwall = async () => {
        // log('show regwall > 1');
        const iframeUrl = await metering.fetchGoogleSignInIframeUrl()
        GaaMeteringRegwall.show({
          // Specify a URL that renders a Sign In with Google button.
          // See the Sign In with Google widget section.
          iframeUrl,
        })
          .then((googleUserState) => {
            // A.5biii) Handle registration for new users
            // Send the credential object to your Registration endpoint.
            // Return a userState object to represent the
            // newly-registered user.
            // log('show regwall > googleUserState', googleUserState)
            metering.registerUser(googleUserState)
          })
          .then(() => {
            // Send the userState object for the newly-registered
            // user to Google.
            syncEntitlementsWithGoogle()
          })
      }
      const getUserState = async () => {
        const meterState = await metering.fetchMeteringState()
        return formatUserState(meterState)
      }
      // Step A: Handle anonymous Showcase users.
      // A.1) Check if the user came from showcase
      if (isGaa()) {
        // A.2) Check if the article is free
        if (metering.isArticleFree(pianoMeter)) {
          log('EVENT_SHOWCASE_UNLOCKED_FREE_PAGE')
          subscriptions.setShowcaseEntitlement({
            entitlement: 'EVENT_SHOWCASE_UNLOCKED_FREE_PAGE',
            isUserRegistered: isUserRegistered(),
          })
          // A.3) Check if the publisher knows the user or not.
        } else if (isLogin()) {
          // log('logged in user, check entitlement')
          // A.4) The publisher knows who the user is already.
          // Create a userState object for the user and send it to Google
          syncEntitlementsWithGoogle()
          // A.5) The user is anonymous and is trying to read a paywalled article.
        } else {
          // If the publisher has a meter for anonymous users, the publisher should check
          // if the user has reads left from that meter.
          if (anonymousUserHasAccess(pianoMeter)) {
            log('EVENT_SHOWCASE_UNLOCKED_BY_METER')
            // A.5a) The user has access from the anonymous user meter
            subscriptions.setShowcaseEntitlement({
              entitlement: 'EVENT_SHOWCASE_UNLOCKED_BY_METER',
              isUserRegistered: false,
            })
            resolve(null)
          } else {
            // A.5b) The publisher does not have a meter for anonymous users or the current
            // user has run out of reads.
            // A.5bi) Show the registration intervention
            showGoogleRegwall()
            // A.5bii) Handle login for existing users
            subscriptions.setOnLoginRequest(login)
          }
        }
      }
      // Step B: Sync entitlements with Google.
      subscriptions.setOnEntitlementsResponse((entitlementsPromise) => {
        // Wait for Google check and publisher check to finish
        Promise.all([entitlementsPromise]).then((entitlements) => {
          // Determine Google response from publisher response.
          const [googleEntitlement] = entitlements
          if (pianoMeter !== METER_EXPIRED) {
            // B.1b) User has access from publisher so unlock article
            // At the same time, share information about the entitlement with Google.
            // See here for the specification of PublisherEntitlement: https://git.io/Jk1SW
            if (pianoMeter === SUBSCRIPTION_ACTIVE) {
              log('EVENT_SHOWCASE_UNLOCKED_BY_SUBSCRIPTION')
              // B.1ai) The user has access because they have a subscription
              subscriptions.setShowcaseEntitlement({
                entitlement: 'EVENT_SHOWCASE_UNLOCKED_BY_SUBSCRIPTION',
                isUserRegistered: isUserRegistered(),
              })
            } else if (pianoMeter === METER_ACTIVE) {
              log('EVENT_SHOWCASE_UNLOCKED_BY_METER')
              // B.1aii) The user has access from the publisher's meter
              subscriptions.setShowcaseEntitlement({
                entitlement: 'EVENT_SHOWCASE_UNLOCKED_BY_METER',
                isUserRegistered: isUserRegistered(),
              })
            }
            resolve(GOOGLE_EXTENDED_ACCESS)
          } else if (googleEntitlement.enablesThisWithGoogleMetering()) {
            // B.2a) Google returned metering entitlement so grant access
            googleEntitlement.consume(() => {
              // B.2ai) Consume the entitlement and trigger a dialog that lets the user
              // know Google provided them with a free read.
              resolve(GOOGLE_EXTENDED_ACCESS)
            })
          } else {
            log('EVENT_SHOWCASE_NO_ENTITLEMENTS_PAYWALL')
            // B.3a) User does not any access from publisher or Google so show the standard paywall
            subscriptions.setShowcaseEntitlement({
              entitlement: 'EVENT_SHOWCASE_NO_ENTITLEMENTS_PAYWALL',
              isUserRegistered: isUserRegistered(),
            })
            // B.3b) Show the paywall
            log('EVENT_SHOWCASE_NO_ENTITLEMENTS_PAYWALL')

            // A user was shown a paywall
            window._cbq = window._cbq || []
            window._cbq.push(['trackPaywallShown'])

            resolve(GOOGLE_INVALID_EXTENDED_ACCESS)
          }
        })
      })
      // B.2aiii) Handle "Subscribe" click
      subscriptions.setOnNativeSubscribeRequest(() => openSubscriptionPage())
    })
  })

export const watchMeter = () =>
  new Promise((resolve) => {
    window.tp = window.tp || []
    tp.push(['addHandler', METER_EXPIRED, () => resolve(METER_EXPIRED)])
    tp.push(['addHandler', METER_ACTIVE, () => resolve(METER_ACTIVE)])
    tp.push([
      'addHandler',
      'setResponseVariable',
      (eventParams) => {
        if (eventParams?.responseVariables?.subscribed === true) {
          resolve(SUBSCRIPTION_ACTIVE)
        }
      },
    ])
    // fallback method if no experience runs
    if (JSGlobals.pageType === 'article') {
      setTimeout(() => resolve(NOT_METERED), 10000)
    }
  })

export const validExtendedAccess = async () => {
  const hasFlag = metering.getTemporaryFlag()
  if (hasFlag) {
    await metering.removeTemporaryFlag()
    return GOOGLE_EXTENDED_ACCESS
  }
  return null
}
