// @TODO: Remove the eslint rule - this seems to be due to the boilerplate Permutive code
/* eslint-disable @typescript-eslint/no-unused-expressions */
/*globals JSGlobals*/
import { jsLoader } from './fileLoaders'
import { getPermutiveIDs } from '~/constants/permutiveIDs'

const { ORGANIZATION_ID, WORKSPACE_ID, WORKSPACE_PUBLIC_API_KEY } =
  getPermutiveIDs()

export const initPermutiveTag = () => {
  jsLoader(
    [`https://${ORGANIZATION_ID}.edge.permutive.app/${WORKSPACE_ID}-web.js`],
    'permutive',
  )
    .then(() => {
      !(function (e, o, n, i) {
        if (!e) {
          ;(e = e || {}), (window.permutive = e), (e.q = [])
          var t = function () {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
              /[018]/g,
              function (e) {
                return (
                  e ^
                  ((window.crypto || window.msCrypto).getRandomValues(
                    new Uint8Array(1),
                  )[0] &
                    (15 >> (e / 4)))
                ).toString(16)
              },
            )
          }
          ;(e.config = i || {}),
            (e.config.apiKey = o),
            (e.config.workspaceId = n),
            (e.config.environment = e.config.environment || 'production'),
            (window.crypto || window.msCrypto) && (e.config.viewId = t())

          for (
            var g = [
                'addon',
                'identify',
                'track',
                'trigger',
                'query',
                'segment',
                'segments',
                'ready',
                'on',
                'once',
                'user',
                'consent',
              ],
              r = 0;
            r < g.length;
            r++
          ) {
            var w = g[r]
            e[w] = (function (o) {
              return function () {
                var n = Array.prototype.slice.call(arguments, 0)
                e.q.push({ functionName: o, arguments: n })
              }
            })(w)
          }
        }
      })(window.permutive, WORKSPACE_PUBLIC_API_KEY, WORKSPACE_ID, {
        consentRequired: true,
      })
      ;(window.googletag = window.googletag || {}),
        (window.googletag.cmd = window.googletag.cmd || [])
      window.googletag.cmd.push(function () {
        if (0 === window.googletag.pubads().getTargeting('permutive').length) {
          var e = window.localStorage.getItem('_pdfps')
          window.googletag
            .pubads()
            .setTargeting('permutive', e ? JSON.parse(e) : [])
          var o = window.localStorage.getItem('permutive-id')
          o &&
            (window.googletag.pubads().setTargeting('puid', o),
            window.googletag
              .pubads()
              .setTargeting('ptime', Date.now().toString()))
          window.permutive.config.viewId &&
            window.googletag
              .pubads()
              .setTargeting('prmtvvid', window.permutive.config.viewId)
          window.permutive.config.workspaceId &&
            window.googletag
              .pubads()
              .setTargeting('prmtvwid', window.permutive.config.workspaceId)
        }
      })

      const {
        article = {},
        articleType = '',
        pageId = '',
        pageType = '',
        paragraphCount = 0,
        premium = false,
        sensitive = false,
        title = '',
      } = JSGlobals

      window.permutive.addon('web', {
        page: {
          classifications_watson: {
            categories: '$alchemy_taxonomy',
            concepts: '$alchemy_concepts',
            emotion: '$alchemy_document_emotion',
            entities: '$alchemy_entities',
            keywords: '$alchemy_keywords',
            sentiment: '$alchemy_document_sentiment',
          },
          author: article?.authors[0].name || '',
          sections: article?.sections?.join(', ') || '',
          sensitive: sensitive,
          article: {
            title: article?.title || '',
          },
          premium: premium,
          pagetype: pageType,
          articleType: articleType || '',
          siteSections: article?.siteSections?.flat() || [],
          title: title,
          pageID: pageId,
          topics: {
            name: article?.topics?.map((topic) => topic.name) || [],
          },
          paragraphCount: parseInt(paragraphCount) || 0,
        },
      })
    })
    .catch((error) => {
      console.error('Error loading Permutive script:', error)
    })
}
