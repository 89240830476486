export const pid_cookie_name = '_gea_ppid'
export const metering_timestamp_cookie_name = '_gea_timestamp'
export const metering_prefix_flag = '_gea_'
export const after_login_page = '_gea_login_back_url_'
export const piano_login_cookie_name = '__utp'
export const GOOGLE_EXTENDED_ACCESS = 'google_extended_access'
export const GOOGLE_INVALID_EXTENDED_ACCESS = 'google_invalid_access'
export const METER_EXPIRED = 'meterExpired'
export const METER_ACTIVE = 'meterActive'
export const SUBSCRIPTION_ACTIVE = 'subscriptionActive'
export const NOT_METERED = 'notMetered'
