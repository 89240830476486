/**
 * Constructs a subscription redirect URL with the provided parameters.
 *
 * This function builds a URL with query parameters for a subscription
 * checkout flow. It takes various optional and required parameters,
 * constructs query parameters based on their presence, and appends
 * them to the provided path.
 *
 * @param {Object} params - The parameters for the URL construction.
 * @param {string} params.offerId - The ID of the offer.
 * @param {string} [params.termId] - The optional term ID.
 * @param {string} [params.promoCode] - The optional promotional code.
 * @param {string} [params.checkoutFlowId] - The optional checkout flow ID.
 * @param {string} [params.targetedTermId] - The optional targeted term ID.
 * @param {string} params.path - The base path for the URL.
 * @returns {string} - The constructed URL with the query parameters.
 */
export const buildSubscriptionRedirectUrl = ({
  offerId,
  termId,
  promoCode,
  checkoutFlowId,
  targetedTermId,
  path,
}: {
  offerId: string
  termId?: string
  promoCode?: string
  checkoutFlowId?: string
  targetedTermId?: string
  path: string
}): string => {
  const query = new URLSearchParams({
    offerId,
    ...(termId ? { termId } : targetedTermId ? { targetedTermId } : {}),
    ...(promoCode && { promoCode }),
    ...(checkoutFlowId && { checkoutFlowId }),
  })

  return `${path}?${query.toString()}`
}
