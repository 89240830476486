import polyfill from '~/js/polyfill'
import comscore from '~/util/comscore'
import { instagramEmbed } from '~/util/get-external-scripts'
import { initGTMScript } from '~/util/gtm'
import { initIpsos } from '~/util/lpsos'
import { initTaboolaTag } from './taboola'
import { moreLess } from '~/util/moreLess'
import { initParselyAnalytics } from '~/util/parselyAnalytics'
import { initPermutiveTag } from '~/util/permutive'
import reportWebVitals from '~/util/reportWebVitals'
import { initGoogleSearchSelector } from '~/util/search-selector'
import { isThirdPartyModeEnabled } from '~/util/speed-tool'

export const initialisePreConsent = (pageType: string): void => {
  // Map script aliases with their corresponding functions for speed-tool test
  // when using query param, ie. ?3rd-party-mode=placeholder&gtm-mode=enabled
  const scriptAliases: Record<string, () => void> = {
    polyfill,
    webvitals: reportWebVitals,
    gtm: initGTMScript, // Google Tag Manager
    parsely: initParselyAnalytics,
    initPermutiveTag, // Permutive audience data capture
    ipsos: initIpsos, // Ipsos audience measurement
    comscore, // Comscore audience measurement
    instagram: instagramEmbed, // Instagram embed
    moreless: moreLess, // Loaded here as a workaround for Safari script loading issue (https://jpimediapublishing.atlassian.net/browse/EWB-493)
    googlesearch: initGoogleSearchSelector, // Loaded here so that it's ready if search is clicked / DetailedMenu opened immediately on page load
  }

  // Add Taboola script based on pageType
  if (
    pageType === 'article' ||
    pageType === 'live blog article' ||
    pageType === 'video article'
  ) {
    scriptAliases['initTaboolaTag'] = () => initTaboolaTag('article')
  } else if (pageType === 'photo article') {
    scriptAliases['initTaboolaTag'] = () => initTaboolaTag('photo')
  }

  const { isEnabled, enabledValues } = isThirdPartyModeEnabled()

  // Iterate over each script alias for initialisation
  Object.entries(scriptAliases).forEach(([alias, initFunction]) => {
    // Check if the script should be enabled based on query params
    const isScriptEnabled = enabledValues.includes(alias)
    // If third-party mode is enabled and the script is not enabled, skip initialisation
    if (isEnabled && !isScriptEnabled) {
      return
    }

    // Otherwise, initialise the script
    initFunction()
  })
}
