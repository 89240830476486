import { initPiano, loadPiano } from '~/js/piano'
import {
  initGoogleExtendedAccess,
  validExtendedAccess,
  watchMeter,
} from './google/extendedAccess'
import { getCookie } from '~/js/settings'
import { GOOGLE_EXTENDED_ACCESS } from './google/constant'
import { initAxate } from './axate'
import metering from './google/metering'
import initViafoura from './viafoura'
import { isThirdPartyModeEnabled } from '~/util/speed-tool'

const APSTAG_PROJECT_ALIAS = 'userstatus'
const { isEnabled, enabledValues } = isThirdPartyModeEnabled()
const isScriptEnabled = enabledValues.includes(APSTAG_PROJECT_ALIAS)
export const userStatus = () => {
  if (isEnabled && !isScriptEnabled) {
    return
  }

  if (
    window.JSGlobals?.piano &&
    !(
      window.JSGlobals.mode === 'development' &&
      getCookie('pianoDisabled') === 'true'
    )
  ) {
    const axateEnabled = ['yorkshirepost.co.uk', 'newsletter.co.uk'].includes(
      window.JSGlobals.domain,
    )
    if (window.JSGlobals.enableGoogleExtendedAccess) {
      // @TODO debug extended access
      watchMeter()
        .then(initGoogleExtendedAccess)
        .then((tag) => {
          if (tag === GOOGLE_EXTENDED_ACCESS) {
            metering.setTemporaryFlag()
            window.location.reload()
          }
        })

      loadPiano()
        .then(async () => {
          const tag = await validExtendedAccess()
          console.log('g-e-a >> dirty amp tag', tag)
          initPiano(tag)
        })
        .then(() => {
          if (window?.JSGlobals?.viafoura) {
            setTimeout(initViafoura, 1800)
          }
        })
        .then(() => {
          if (axateEnabled) {
            initAxate()
          }
        })
    } else {
      loadPiano()
        .then(() => initPiano(null))
        .then(() => {
          if (window?.JSGlobals?.viafoura) {
            setTimeout(initViafoura, 1800)
          }
        })
        .then(() => {
          if (axateEnabled) {
            initAxate()
          }
        })
    }
  }
}
