/*globals PARSELY */
import { jsLoader } from './fileLoaders'
import { isLogin, isPianoSubscriber } from '~/js/piano'
import { axateSubscriber } from './axate'

const segmentType = () => {
  if (isPianoSubscriber() || axateSubscriber()) {
    return 'subscriber'
  } else if (isLogin()) {
    return 'registrant'
  } else {
    return 'anonymous'
  }
}

export const initParselyAnalytics = async () => {
  window.PARSELY = (await window.PARSELY) || {
    autotrack: false,
    onReady: () => {
      PARSELY.updateDefaults({
        data: {
          user_type: segmentType(),
        },
      })
      PARSELY.beacon.trackPageView()
    },
  }

  const untrackedPaths = ['/dev/header', '/dev/footer']
  const untrackedQueries = ['callback=in']

  if (
    !untrackedPaths.includes(window.location.pathname) &&
    !untrackedQueries.some((query) => window.location.search.includes(query))
  ) {
    jsLoader(
      [
        {
          src: `//cdn.parsely.com/keys/${window.JSGlobals.domain}/p.js`,
          id: 'parsely-cfg',
        },
      ],
      'parsely',
    )
  }
}
