export const moreLess = () => {
  const collapsedClass = 'collapsed'
  const shortClass = 'short'
  const $moreLessOuterWrapper = document.getElementById(
    'more-less-outer-wrapper',
  )
  const $moreLessInnerWrapper = document.getElementById(
    'more-less-inner-wrapper',
  )
  const $moreLessContent = document.getElementById('more-less-content')
  const $moreLessButton = document.getElementById('more-less-button')

  if (
    !$moreLessOuterWrapper ||
    !$moreLessInnerWrapper ||
    !$moreLessContent ||
    !$moreLessButton
  )
    return

  let collapsedHeight = $moreLessContent.offsetHeight
  let isCollapsed = $moreLessOuterWrapper.classList.contains(collapsedClass)

  const expand = () => {
    collapsedHeight = $moreLessContent.offsetHeight

    isCollapsed = false
    $moreLessOuterWrapper.classList.remove(collapsedClass)
  }

  const collapse = () => {
    isCollapsed = true
    $moreLessOuterWrapper.classList.add(collapsedClass)

    // Adjust in case viewport resized since last time
    collapsedHeight = $moreLessContent.offsetHeight
    $moreLessInnerWrapper.style.height = `${collapsedHeight}px`
  }

  /**
   * This is so that the text doesn't get clamped abruptly. The wrapper will
   * get resized first, then the content inside will get clamped.
   */
  const collapseLeadingWithWrapper = () => {
    const eventOptions = { once: true }

    let isCollapsing = false
    const setIsCollapsing = () => (isCollapsing = true)

    $moreLessInnerWrapper.addEventListener(
      'transitionrun',
      setIsCollapsing,
      eventOptions,
    )
    $moreLessInnerWrapper.addEventListener(
      'transitionend',
      collapse,
      eventOptions,
    )

    // This will trigger the transision
    $moreLessInnerWrapper.style.height = `${collapsedHeight}px`

    // In case transition doesn't happen, collapse anyway
    setTimeout(() => {
      if (!isCollapsing) {
        $moreLessInnerWrapper.removeEventListener('transitionend', collapse)
        $moreLessInnerWrapper.removeEventListener(
          'transitionrun',
          setIsCollapsing,
        )
        collapse()
      }
    }, 100)
  }

  $moreLessButton.addEventListener('click', () => {
    if (isCollapsed) {
      expand()
    } else {
      collapseLeadingWithWrapper()
    }
  })

  const resizeCallback = () => {
    const height = $moreLessContent.offsetHeight
    if (isCollapsed) collapsedHeight = height

    // Keep Inner Wrapper height in line with content height
    $moreLessInnerWrapper.style.height = `${height}px`

    const tolerance = 12 // This should be less than a line of content
    const hasMoreTextToShow = height + tolerance < $moreLessContent.scrollHeight
    const isShowingMoreText = height > collapsedHeight

    if (hasMoreTextToShow || (isShowingMoreText && !isCollapsed)) {
      // Remove "short" class if enough text to collapse/expand
      $moreLessOuterWrapper.classList.remove(shortClass)
    } else {
      // Mark as short if text too short
      $moreLessOuterWrapper.classList.add(shortClass)
    }
  }

  const observer = new ResizeObserver(resizeCallback)
  observer.observe($moreLessContent)
}
