/*globals JSGlobals */

const createContinueBrowsingListener = (
  container,
  elements,
  isMidContent = false,
) => {
  return (evt) => {
    if (!evt.data) return
    if (
      !['https://sandbox.tinypass.com', 'https://buy.tinypass.com'].includes(
        evt.origin,
      )
    )
      return

    if (evt.data === 'continue-browsing') {
      if (!isMidContent) {
        container?.classList.remove('datawall-hidden')
      }
      elements?.forEach((element) => {
        element.classList.remove('hidden')
      })
    }
  }
}

const shouldSkipElement = (element) => {
  const skipClassesRegex =
    /taboola-bottomfeed|article-meta|article-content|piano-article-paywall|axate-wallet/

  if (skipClassesRegex.test(element.className)) {
    return true
  }

  if (
    element.id === 'gallery-main-content' ||
    element.id === 'gallery-ob-container'
  ) {
    return true
  }

  return false
}

const hideArticleContent = ({
  isMidContent = false,
  disableContinueBrowsingListener = false,
}) => {
  const container =
    document.querySelector('.article-content') ||
    document.querySelector('#content-wrapper')

  const elements = container?.querySelectorAll(':scope > *')

  if (!isMidContent) {
    const photoArticleImageCount =
      document.querySelectorAll('.caption-gallery').length
    const paragraphCount = document.querySelectorAll('.markup').length
    let hasShownParagraph = false

    // pause dailymotion
    container?.querySelectorAll('iframe').forEach(function (iframe) {
      iframe.contentWindow.postMessage('pause', '*')
    })

    // add article fade - unless there is only one image in a gallery
    if (photoArticleImageCount !== 1) {
      container?.classList.add('datawall-hidden')
    }

    elements?.forEach((element) => {
      if (shouldSkipElement(element)) {
        return
      }

      // show first paragraph if article contains more than 1 paragraph
      if (
        paragraphCount > 1 &&
        !hasShownParagraph &&
        element.className.includes('markup')
      ) {
        hasShownParagraph = true
        return
      }

      element.classList.add('hidden')
    })
  } else {
    let hasFoundTaboolaMid = false

    elements?.forEach((element) => {
      if (hasFoundTaboolaMid) {
        if (shouldSkipElement(element)) {
          return
        }
        element.classList.add('hidden')
      }

      if (element.id === 'taboola-mid-0') {
        hasFoundTaboolaMid = true
      }
    })
  }

  if (!disableContinueBrowsingListener) {
    window.addEventListener(
      'message',
      createContinueBrowsingListener(container, elements, isMidContent),
    )
  }
}

export const pianoHideArticleContent = (
  disableContinueBrowsingListener = false,
) => {
  hideArticleContent({ isMidContent: false, disableContinueBrowsingListener })
}

export const pianoHideArticleContentMid = (
  disableContinueBrowsingListener = false,
) => {
  const isPianoPaywallMidInDom = document.querySelector(
    '.piano-article-paywall-mid',
  )

  if (!isPianoPaywallMidInDom) return

  hideArticleContent({ isMidContent: true, disableContinueBrowsingListener })
}

export const pianoRemoveArticleContent = async () => {
  const container =
    document.querySelector('.article-content') ||
    document.querySelector('#content-wrapper')

  container?.querySelectorAll('iframe').forEach(function (iframe) {
    iframe.contentWindow.postMessage('pause', '*')
  })

  const elements = container?.querySelectorAll(':scope > *')
  const photoArticleImageCount =
    document.querySelectorAll('.caption-gallery').length
  const paragraphCount = document.querySelectorAll('.markup').length
  let hasShownParagraph = false

  // add article fade - unless there is only one image in a gallery
  if (photoArticleImageCount !== 1) {
    container?.classList.add('datawall-hidden')
  }

  let whitelistedClassNames = [
    'taboola-bottomfeed',
    'article-meta',
    'article-content',
    'axate-wallet',
  ]

  //ensure piano article paywalls still appear on non premium articles
  //even on axate enabled sites. This allows things like page metering that may call this
  //function to work
  if (!window.removeAxateContent || !JSGlobals.premium) {
    whitelistedClassNames.push('piano-article-paywall')
  }

  const whitelistRegex = new RegExp(whitelistedClassNames.join('|'))

  elements?.forEach((element) => {
    // don't hide elements with these classnames
    if (whitelistRegex.test(element.className)) {
      return
    }

    // don't remove sections with these ids on PAPs
    if (
      element.id === 'gallery-main-content' ||
      element.id === 'gallery-ob-container'
    ) {
      return
    }

    // only hide mid-article outbrain - removing it will break the bottom outbrain feed
    if (/OUTBRAIN/.test(element.className)) {
      element.style.display = 'none'
      return
    }
    // show first paragraph if article contains more than 1 paragraph
    if (
      paragraphCount > 1 &&
      !hasShownParagraph &&
      element.className.includes('markup')
    ) {
      hasShownParagraph = true
      return
    }
    // remove this element
    container.removeChild(element)
  })

  if (!window.removeAxateContent) {
    container.classList.remove('no-entitlement')
  }
}

export const pianoRemoveDisqusComments = () => {
  const container =
    document.querySelector('.article-content') ||
    document.querySelector('#content-wrapper')
  const elements = container?.querySelectorAll(':scope > *')

  elements?.forEach((element) => {
    if (element.querySelector(':scope > a[href="#disqus-comment-section"]')) {
      element.style.display = 'none'
    }
  })
}

export const pianoHideHubspotNewsletter = () => {
  if (document.querySelector('#article-newsletter-signup')) {
    document.querySelector('#article-newsletter-signup').style.display = 'none'
  }
  if (document.querySelector('#mobile-article-newsletter-signup')) {
    document.querySelector('#mobile-article-newsletter-signup').style.display =
      'none'
  }
}

export const pianoHideHubspotNewsletterRHR = () => {
  if (document.querySelector('#sidebar-newsletter-signup')) {
    const rhrNewsletters = document.querySelectorAll(
      '#sidebar-newsletter-signup',
    )
    rhrNewsletters.forEach((newsletter) => {
      newsletter.style.display = 'none'
    })
  }
}

export const pianoHideCustomNewsletter = () => {
  if (document.querySelector('#customNewsletterComp')) {
    const customNewsletterComp = document.querySelectorAll(
      '#customNewsletterComp',
    )
    customNewsletterComp.forEach((newsletter) => {
      newsletter.style.display = 'none'
    })
  }
}

export const pianoHidePuzzles = () => {
  const puzzleWrapper = document.querySelector(
    '.piano-puzzle-wrapper #pa-iframe',
  ).parentElement
  const puzzleContent = document.querySelectorAll(
    '.piano-puzzle-wrapper #pa-iframe',
  )

  puzzleContent.forEach((p) => {
    puzzleWrapper.removeChild(p)
  })
}

export const pianoHideNewsletterByIds = (ids) => {
  ids.forEach((id) => {
    const newsletter = document.querySelector(`#${id}`)
    if (newsletter) {
      newsletter.remove()
    }
  })
}

export const removeAxateContent = () => {
  //no op
}
