export const initAirGrid = () => {
  ;(() => {
    var edktInitializor = (window.edktInitializor =
      window.edktInitializor || {})
    if (!edktInitializor.invoked) {
      edktInitializor.invoked = true
      edktInitializor.accountId = 'adVxRZ'
      edktInitializor.publisherId = '9y1vtC'
      edktInitializor.apiKey = 'mu2zXOL6jtWKocmCiGyqlZmAOLw2'
      edktInitializor.load = function (e) {
        var p = e ? e : 'sdk'
        var n = document.createElement('script')
        n.type = 'text/javascript'
        n.async = true
        n.src = 'https://cdn.edkt.io/' + p + '/edgekit.min.js'
        var a = document.getElementsByTagName('script')[0]
        a.parentNode.insertBefore(n, a)
      }
      edktInitializor.load(edktInitializor.accountId)
    }
  })()
}

export const getEDKTAudiences = () => {
  let edktAudiences = []

  try {
    edktAudiences = JSON.parse(
      localStorage.getItem('edkt_matched_audience_ids') || '[]',
    )
      .slice(0, 100)
      .map(String)
  } catch (e) {
    // noop
  }

  return edktAudiences
}
