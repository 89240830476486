/*globals google*/
const initGoogleSearch = (cx) => {
  if (!cx) return

  var gcse = document.createElement('script')
  gcse.type = 'text/javascript'
  gcse.async = true
  gcse.setAttribute('data-name', 'google-search-script')
  gcse.src = 'https://cse.google.com/cse.js?cx=' + cx
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(gcse, s)

  const searchElement = 'searchBox0'

  const renderSearchElement = () => {
    google.search.cse.element.render(
      {
        div: searchElement,
        tag: 'searchbox',
        gname: 'searchbox-only0',
      },
      {
        div: 'quickSearchresults0',
        tag: 'searchresults-only',
        gname: 'searchbox-only0',
      },
    )
    google.search.cse.element.render(
      {
        div: 'searchBox1',
        tag: 'searchbox',
        gname: 'searchbox-only1',
      },
      {
        div: 'quickSearchresults1',
        tag: 'searchresults-only',
        gname: 'searchbox-only1',
      },
    )
  }

  const callback = () => {
    if (typeof document.getElementById(searchElement) !== 'undefined') {
      renderSearchElement()
      document.getElementById('gsc-i-id1').placeholder = 'Google Custom Search'

      const searchInput = document.getElementById(searchElement)
      const autoSuggestionContainer =
        document.getElementsByClassName('gstl_50')[1]

      searchInput.addEventListener('click', () => {
        const autoSuggestionsTable = document.getElementsByClassName(
          'gsc-completion-container',
        )[0]
        if (autoSuggestionsTable?.rows.length) {
          autoSuggestionContainer.style.cssText += 'display:block'
        } else {
          autoSuggestionContainer.style.cssText += 'display:none'
        }
      })
      searchInput.addEventListener('focusout', () => {
        autoSuggestionContainer.style.cssText += 'display:none'

        const isSearchInputEmpty =
          document.getElementById('gs_cb50')?.offsetParent === null
        const searchInput = document.querySelector('.search-input')
        const searchToggle = document.querySelector('.search-toggle')
        if (isSearchInputEmpty) {
          searchInput?.classList.add('hidden')
          searchToggle?.classList.remove('hidden')
        }
      })
    } else {
      google.setOnLoadCallback(renderSearchElement, true)
    }
  }

  window.__gcse = {
    parsetags: 'explicit',
    callback: callback,
  }
}

export default initGoogleSearch
