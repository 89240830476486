/*global JSGlobals, DotMetricsObj, dm*/
import { lpsos } from '~/config/lpsos'

export const initIpsos = () => {
  const untrackedPaths = ['/dev/header', '/dev/footer']
  if (untrackedPaths.includes(location.pathname)) {
    return
  }
  const { pathname } = window.location
  const parts = pathname.split('/').filter(Boolean)
  let path = parts
  if (/article/.test(JSGlobals.pageType) && parts.length > 1) {
    parts.pop()
    path = parts.join('/')
  }

  const siteSectionNameString = lpsos[path] || 'other'

  ;(function () {
    window.dm = window.dm || { AjaxData: [] }
    window.dm.AjaxEvent = function (et, d, ssid, ad) {
      dm.AjaxData.push({ et: et, d: d, ssid: ssid, ad: ad })
      window.DotMetricsObj && DotMetricsObj.onAjaxDataUpdate()
    }
    var d = document,
      h = d.getElementsByTagName('head')[0],
      s = d.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src =
      'https://uk-script.dotmetrics.net/door.js?d=' +
      document.location.host +
      '&t=' +
      siteSectionNameString
    h.appendChild(s)
  })()
}
