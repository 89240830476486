/*globals JSGlobals, tp*/
import { getCookie, setCookie } from '~/util/cookie'
import { metering_prefix_flag, NOT_METERED } from './constant'

export const fetchMeteringState = async () => {
  const loggedIn = tp.pianoId.isUserValid()
  let pianoId = ''
  if (loggedIn) {
    const { uid } = tp.pianoId.getUser()
    pianoId = uid
  }
  return fetch('/api/gea/pid', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid: pianoId }),
  })
    .then((response) => response.json())
    .catch(() => null)
}

export const registerUser = async (gaaUser) => {
  const { email, givenName, familyName } = gaaUser
  return fetch('/api/gea/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, givenName, familyName }),
  }).then((response) => response.json())
}

export const fetchGoogleSignInIframeUrl = async () => {
  const redirect = location.href
  return fetch('/api/gea/auth-url', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ redirect }),
  })
    .then((response) => response.json())
    .then(
      (response) =>
        document.location.origin +
        '/google/sign-in?auth=' +
        encodeURIComponent(response?.url),
    )
}

export const fetchPianoSignInToken = async () => {
  const urlParams = new URLSearchParams(window.location.search)
  const response_id = urlParams.get('response_id')
  return fetch('/api/gea/auth-code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ response_id }),
  }).then((response) => response.json())
}

const metering = {
  fetchGoogleSignInIframeUrl,
  fetchPianoSignInToken,
  fetchMeteringState,
  registerUser,
  setTemporaryFlag: () => {
    const name = metering_prefix_flag + JSGlobals.pageId
    setCookie(name, 1, 1) // valid for the session
  },
  getTemporaryFlag: () => {
    return getCookie(metering_prefix_flag + JSGlobals.pageId) === '1'
  },
  removeTemporaryFlag: async () => {
    setCookie(metering_prefix_flag + JSGlobals.pageId, '', -1)
    return Promise.resolve()
  },
  showOverlay: () => {
    document.querySelector('html').classList.add('overlay-gea')
  },
  hideOverlay: () => {
    document.querySelector('html').classList.remove('overlay-gea')
  },
  isArticleFree: (meter) => meter === NOT_METERED,
}

export default metering
