import { jsLoader } from './fileLoaders'

export default () => {
  const untrackedPaths = ['/dev/header', '/dev/footer']
  if (untrackedPaths.includes(location.pathname)) {
    return
  }

  const CLIENT_ID = '18704174' // Descriptive constant for c2 value

  var _comscore = _comscore || []
  _comscore.push({
    c1: '2',
    c2: CLIENT_ID,
    options: {
      enableFirstPartyCookie: true,
    },
  })
  window._comscore = _comscore

  return jsLoader(
    ['https://sb.scorecardresearch.com/cs/' + CLIENT_ID + '/beacon.js'],
    'comscore',
  )
}
