/** This function will return the first part of a string after a value has been placed as
 *  the breaking point to determine where the string will be split. The remainder of the
 *  original string before the value is what will be returned.
 *  @inputString is the original string that will be split into two.
 *  @value is the string that will serve as the breaking point.
 */

export const getStringBeforeValue = (
  inputString: string,
  value: string,
): string => {
  const index = inputString.indexOf(value)

  return index !== -1 ? inputString.substring(0, index) : ''
}
