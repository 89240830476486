import { jsLoader } from '@brightsites/flow-core/lib/utils/fileLoaders'
import { userLogout } from '~/js/piano'

export const setViafouraLogout = () => {
  window?.viafoura?.session?.logout()
}

export const initViafoura = () => {
  const initializeViafoura = () => {
    jsLoader(
      ['//cdn.viafoura.net/entry/index.js'],
      () => {
        window.vfQ = window.vfQ || []
        window.vfQ.push(() => {
          window.dataLayer.push({
            event: 'vfReady',
          })

          window.vf.$prepublish((channel, event, ...args) => {
            if (channel === 'authentication' && event === 'required') {
              const method = args[0]?.method

              //comment widget login/sign up action
              switch (method) {
                case 'login':
                  window.tp.pianoId.show({ flow: 'gmsso' })
                  break
                case 'signup':
                  window.tp.pianoId.show({ screen: 'register' })
                  break
                default:
                  window.vf.$publish('tray', 'close')
                  window.tp.pianoId.show({ flow: 'gmsso' })
                  break
              }

              return false
            }

            return { channel, event, args }
          })

          window.vf.$subscribe('authentication', 'logout', () => {
            userLogout()
          })
        })
      },
      null,
      null,
      true,
    )
  }

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeViafoura)
  } else {
    initializeViafoura()
  }
}

export default initViafoura
