/*globals DISQUS, tp  */
export const initDisqus = () => {
  const discussCommenting = document.querySelector('#disqus_thread')
  if (discussCommenting) {
    ;(function () {
      var d = document,
        s = d.createElement('script')
      s.src = 'https://http-www-scotsman-com.disqus.com/embed.js'
      s.setAttribute('data-timestamp', +new Date())
      ;(d.head || d.body).appendChild(s)
    })()
    // Disqus get comment count script
    ;(function () {
      var d = document,
        s = d.createElement('script')
      s.src = 'https://http-www-scotsman-com.disqus.com/count.js'
      s.setAttribute('id', 'dsq-count-scr')
      s.setAttribute('async', true)
      ;(d.head || d.body).appendChild(s)
    })()
  }
}

export const showLoggedInComments = () => {
  const placeholder = document.querySelector('#disqus-logged-out')
  const widget = document.querySelector('#disqus_thread')

  if (widget && placeholder) {
    widget.style.display = 'block'
    placeholder.style.display = 'none'
  }
}

export const setDisqusLogin = () => {
  const user = tp.pianoId.getUser()
  if (!user) return

  fetch(
    `/internal-api/disqus-login?__amp_source_origin=${window.location.origin}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'same-origin',
      cache: 'no-cache',
      credentials: 'same-origin',
      body: JSON.stringify({
        id: user.uid,
        email: user.email,
        username: user.firstName + ' ' + user.lastName,
      }),
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error('Network response was not ok.')
    })
    .then((response) => reset(response.auth))
    .catch((error) => {
      console.log('Fetch failed: ', error.message)
    })
}

export const setDisqusLogout = () => {
  reset({})
}

/* * * Disqus Reset Function * * */
// do not change below functions to arrow functions
const reset = (newAuth) => {
  return window.DISQUS && document.querySelector('#disqus_thread')
    ? DISQUS.reset({
        reload: true,
        config: function () {
          this.page.remote_auth_s3 = newAuth
          this.page.api_key =
            'aeESOenVB1aP8jXZjDhr13MyB2qKxQVzeOqm2niY0bloyBhas2Xu1GtZRIwmtIdR'
          this.page.url = window.location.href
          this.page.identifier = window.JSGlobals.pageId
        },
      })
    : setTimeout(function () {
        reset(newAuth)
      }, 1500)
}
