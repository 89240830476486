exports.default = [
  'scotsman.com',
  'yorkshireeveningpost.co.uk',
  'thestar.co.uk',
  'portsmouth.co.uk',
  'sunderlandecho.com',
  'blackpoolgazette.co.uk',
  'shieldsgazette.com',
  'ballymenatimes.com',
]
