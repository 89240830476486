/* globals JSGlobals */
import { jsLoader } from './fileLoaders'
import { enableCookies, initChartbeat } from './initChartbeat'

export const isCMPV2 = (domain) => domain === 'chad.co.uk'

export const sourcePoint_v2_0 = () => {
  return new Promise((resolve) => {
    initChartbeat()
    // Do not show consent banner on /subscriptions/consent-subs
    if (location.pathname === '/subscriptions/consent-subs') {
      return resolve(null)
    }

    if (!JSGlobals.sourcePointSiteId) return resolve(null)
    const accountId = 1096
    const privacyId =
      JSGlobals.mode === 'development'
        ? 166634
        : JSGlobals.sourcePointPrivacyManagerIdV2
    window._sp_queue = []
    window._sp_ = {
      config: {
        accountId,
        baseEndpoint: `https://consent.${JSGlobals.domain}`,
        gdpr: {},
        events: {
          onConsentReady: () => {
            enableCookies()
            resolve(null)
          },
          onError: function (errorCode, errorObject, userReset) {
            console.log('errorCode: ' + errorCode)
            console.log(errorObject)
            console.log('userReset: ' + userReset)
            resolve(true)
          },
        },
      },
    }

    if (JSGlobals.mode === 'development') {
      window._sp_.config.propertyHref = 'https://brightsites.co.uk'
    }
    if (location.href.includes('preprod.bs')) {
      window._sp_.config.propertyHref = `https://www.${JSGlobals.domain}`
    }

    const cookieSettings = document.getElementsByClassName('cookie-settings')
    Array.from(cookieSettings).forEach((element) => {
      element.addEventListener('click', () => {
        window._sp_.gdpr.loadPrivacyManagerModal(privacyId)
      })
    })

    jsLoader(
      [
        {
          src: 'https://cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js',
          crossOrigin: '',
          async: false,
        },
      ],
      'sourcepoint',
    )
  })
}
