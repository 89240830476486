import { LAPTOP_BREAKPOINT } from '~/config/theme/JPIMStyles'
import initGoogleSearch from './googleSearch'

const loadGoogleScript = () => {
  const searchScript = document.querySelector(
    '[data-name="google-search-script"]',
  )
  if (!searchScript) {
    initGoogleSearch(window.JSGlobals?.searchId)
  }
}

const handleCloseModal = (
  selector: string,
  searchInput: Element | null,
  searchToggle: Element | null,
) => {
  setTimeout(() => {
    // Wait for it to exist
    const element = document.querySelector(selector)
    if (element) {
      const handleClick = () => {
        searchInput?.classList.add('hidden')
        searchToggle?.classList.remove('hidden')
        element.removeEventListener('click', handleClick)
      }
      element?.addEventListener('click', handleClick)
    }
  }, 350)
}

export const initGoogleSearchSelector = () => {
  // Load script if accessing via side menu on tablet/mobile
  const detailedMenuButton = document.querySelector(
    '[aria-label="Open full menu"]',
  )
  if (detailedMenuButton && window.innerWidth < LAPTOP_BREAKPOINT) {
    detailedMenuButton.addEventListener('click', () => {
      loadGoogleScript()
    })
  }

  // Load script if clicking on the desktop search button
  const searchSelector = document.querySelector('.search-toggle')
  if (searchSelector && window.JSGlobals?.searchId) {
    searchSelector.addEventListener('click', () => {
      loadGoogleScript()
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'site search',
      })

      setTimeout(() => {
        const searchInput = document.querySelector('.search-input')
        const searchToggle = document.querySelector('.search-toggle')
        const searchInputInput = document.querySelector<HTMLInputElement>(
          '.search-input input',
        )

        searchInput?.classList.toggle('hidden')
        searchToggle?.classList.toggle('hidden')
        searchInputInput?.focus()

        const handleBlur = () => {
          // 1. Logic to close search if input is empty and not in focus
          const isSearchInputEmpty =
            document.getElementById('gs_cb50')?.offsetParent === null
          if (isSearchInputEmpty) {
            searchInput?.classList.add('hidden')
            searchToggle?.classList.remove('hidden')
          }

          // 2. Logic to close search field if user clicks outside of search results
          handleCloseModal(
            '.gsc-modal-background-image-visible',
            searchInput,
            searchToggle,
          )

          // 3. Logic to close search field if user clicks close results
          handleCloseModal(
            '.gsc-results-close-btn-visible',
            searchInput,
            searchToggle,
          )

          // Remove the event listener
          searchInputInput?.removeEventListener('blur', handleBlur)
        }

        searchInputInput?.addEventListener('blur', handleBlur)
      }, 350)
    })
  }
}
